import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import styled from "styled-components";
import Page from "../components/Page";
import Header from "../components/Header";
import Content from "../components/styles/Content";
import PageTitle from "../components/styles/PageTitle";
import { changeRgbaOpacity } from "../helpers";

const StyledUl = styled.ul`
  padding: 0 2rem;

  li a {
    color: ${(props) => changeRgbaOpacity(props.theme.linkColor, "0.85")};
  }
`;

const AboutPage = () => (
  <Page>
    <>
      <Helmet title="About" />
      <Header about />
      <Content>
        <PageTitle>About Me</PageTitle>
        <StyledUl>
          <li>Software engineer based in Stockholm, Sweden.</li>
          <li>
            I'm interested in solving interesting problems and building tools
            which help humanity thrive.
          </li>
          {/* <li> */}
          {/*   Currently working at{" "} */}
          {/*   <a */}
          {/*     href="https://www.bemlo.se/" */}
          {/*     target="_blank" */}
          {/*     rel="noopener noreferrer" */}
          {/*   > */}
          {/*     Bemlo */}
          {/*   </a> */}
          {/*   . We are improving health care labor market. */}
          {/* </li> */}
          {/* <li> */}
          {/*   Love working on personal projects, some of which you can check from{" "} */}
          {/*   <Link to="/projects">here</Link>. */}
          {/* </li> */}
          <li>
            I believe reading books is one of the best investments that one can
            do for themselves. It is also quite an enjoyable activity. Feel free
            to check <Link to="/books">what I read</Link>.
          </li>

          <li>Some fun facts about me:</li>
          <StyledUl>
            <ul>
              <li>
                Love automating things. You can find some examples of this in my{" "}
                <Link to="https://github.com/ebozkurt93/dotfiles">
                  dotfiles
                </Link>
                .
              </li>
              <li>
                Avid user of split keyboards. Started using them as due to
                potential health concerns, and ended up with building another
                split keyboard for travel as well.
              </li>
            </ul>
          </StyledUl>
          <li>
            Feel free to check my{" "}
            <a target="_blank" rel="noopener noreferrer" href="/cv">
              CV
            </a>{" "}
            or <Link to="/contact">contact me</Link>.
          </li>
        </StyledUl>
      </Content>
    </>
  </Page>
);
export default AboutPage;
