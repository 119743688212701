function hexToRgba(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)'
    );
  }
  throw new Error('Bad Hex');
}
export function changeRgbaOpacity(color, opacity) {
  // regex to split rgba into r,g,b and opacity
  const regex = /rgba\((\d+),(\d+),(\d+),(\d+\.*\d*)\)/g;
  // eslint-disable-next-line
  let [_, r, g, b, currentOpacity] = regex.exec(hexToRgba(color));
  return `rgba(${r},${g},${b},${opacity})`;
}
